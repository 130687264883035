import "quill-mention";
import { useRef, useState } from "react";
import ReactQuill from "react-quill";

import { Mention, MentionBlot } from "quill-mention";
import "react-quill/dist/quill.snow.css";
import { twMerge } from "tailwind-merge";
import "./index.css";

import { XMarkIcon } from "@heroicons/react/16/solid";
import "quill-mention/autoregister";

ReactQuill.Quill.register({
  "blots/mention": MentionBlot,
  "modules/mention": Mention,
});

type TextEditorInputProps = {
  disabled?: boolean;
  placeholder?: string;
  value?: string;
  onChange?: (e: string) => void;
  reset?: boolean;
  onReset?: () => void;
  // resetProps?: ResetProps;
  className?: string;
  focusOnlyClassName?: string;
};

/* let mentionableUsers: {
  id: string;
  value: string;
}[] = [];
 */
const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
  ],
  /* mention: {
    allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
    mentionDenotationChars: ["@", "#"],
    source: function (
      searchTerm: string,
      renderList: any,
      mentionChar: string
    ) {
      let values: any = [];

      if (mentionChar === "@") {
        values = mentionableUsers;
      }

      if (searchTerm.length === 0) {
        renderList(values, searchTerm);
      } else {
        const matches = [];
        for (let i = 0; i < values.length; i++)
          if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()))
            matches.push(values[i]);
        renderList(matches, searchTerm);
      }
    },
  }, */
};

export const defaultClassName = () => {
  return "shadow-sm border block w-full text-sm border-gray-200 dark:bg-slate-900 dark:border-slate-700 dark:text-white rounded-lg";
};

export const TextEditorInput = (props: TextEditorInputProps) => {
  //const formContext = useContext(FormContextContext);

  const ref = useRef<ReactQuill>(null);
  const [focused, setFocused] = useState(false);

  const disabled = props.disabled ?? false;

  const onEditorChange = (value: any) => {
    // Test if there is content (remove all html tags)
    const hasContent = value.replace(/<[^>]+>/g, "").trim().length > 0;
    props.onChange?.(hasContent ? value : "");
  };

  const defaultPlusPropsClassName = twMerge(
    defaultClassName(),
    props.className
  );

  return (
    <>
      <ReactQuill
        readOnly={disabled}
        ref={ref}
        onKeyDown={(e: any) => {
          // If escape key is pressed, blur the editor
          if (e.key === "Escape") {
            ref.current?.blur();
            setFocused(false);
          }
          // If contains ctrl key, don't stop propagation
          if (e.ctrlKey || e.metaKey) {
            return;
          }
          e.stopPropagation();
        }}
        onKeyUp={(e: any) => {
          e.stopPropagation();
        }}
        onKeyPress={(e: any) => {
          e.stopPropagation();
        }}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        placeholder={props.placeholder ?? "Cliquer pour ajouter une note"}
        className={twMerge(
          "editor-input",
          disabled && "is-disabled",
          !disabled && "p-2 pr-0 bg-white dark:bg-slate-950",
          focused && "has-focus",
          focused && props.focusOnlyClassName,
          defaultPlusPropsClassName
        )}
        theme="snow"
        value={props.value || ""}
        onChange={onEditorChange}
        modules={modules}
      />
      {props.reset && (
        <XMarkIcon
          onClick={props.onReset}
          className="w-4 h-6 bg-white dark:bg-slate-800 text-xs text-slate-400 cursor-pointer absolute right-2 top-1/2 -translate-y-1/2"
        />
      )}
    </>
  );
};
