import { SessionControlType } from "@features/sessions/types";
import { getFirstNodes } from "@features/sessions/utils";
import { Control } from "./control";

type ControlGroup = {
  key: string;
  title: string;
};

type ControlsProps = {
  annotable?: boolean;
  controls: SessionControlType[];
  groups?: ControlGroup[];
};

export function Controls({ controls, groups, annotable }: ControlsProps) {
  let firstNodes: ControlGroup[] = [];
  if (groups) {
    firstNodes = groups;
  } else {
    const nodes = getFirstNodes(controls);
    firstNodes = nodes.map((node) => ({
      key: node.identifier,
      title: node.titleMsg,
    }));
  }
  firstNodes = firstNodes.filter(
    (group) =>
      controls.filter((control) => control.identifier === group.key).length > 0
  );

  const convertToTree = (
    group: ControlGroup,
    depth = 1,
    depthMap: boolean[] = []
  ): JSX.Element => {
    const control = controls.find(
      (control) => control.identifier === group.key
    );
    if (!control) return <></>;
    const controlsAsGroup = controls
      .filter(
        (control) =>
          control.identifier.startsWith(group.key) &&
          control.identifier !== group.key
      )
      .map((control) => ({
        key: control.identifier,
        title: control.titleMsg,
      }));

    // Use child functions to create the tree
    return (
      <>
        <Control
          result={control.result}
          title={control.titleMsg}
          description={control.resultMsg}
          details={control.details}
          identifier={control.identifier}
          depth={depth}
          depthMap={[...depthMap, (controlsAsGroup || []).length > 0]}
          annotable={annotable}
        />{" "}
        {(controlsAsGroup || []).map((sub: ControlGroup, i: number) =>
          convertToTree(sub, depth + 1, [
            ...depthMap,
            i < (controlsAsGroup || []).length - 1,
          ])
        )}
      </>
    );
  };

  return (
    <div className="flex flex-wrap">
      {firstNodes.map((group, i) => {
        return (
          <div className="relative grow shrink-0 w-1/5" key={i}>
            {convertToTree(group)}
          </div>
        );
      })}
    </div>
  );
}
