import { BaseSmall, Info } from "@atoms/text";
import { useThreadMessages } from "@features/inbox/state/use-thread-messages";
import { InboxMessage } from "@features/inbox/types";
import { htmlDecodeRawTextContent } from "@features/utils/strings";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import FuzzySet from "fuzzyset.js";
import { useEffect, useRef } from "react";

export const WarningReusingMessage = (props: {
  message: { subject?: string; content?: string };
  threadId: string;
}) => {
  const { feed } = useThreadMessages(props.threadId || "");
  const fuzzySet = useRef(FuzzySet());

  useEffect(() => {
    const previousMessages = feed
      .filter((item) => item.type === "message")
      .map((item) => item.data as InboxMessage);
    for (const message of previousMessages) {
      fuzzySet.current.add(
        htmlDecodeRawTextContent(message.content ?? "") || ""
      );
    }
  }, [feed]);

  let isDuplicate = false;
  let duplicatedMessage = undefined;
  const similarity = fuzzySet.current.get(
    htmlDecodeRawTextContent(props.message?.content || "") || ""
  );
  // If the similarity is greater than or equal to 0.8, return true
  if (similarity && similarity[0][0] >= 0.8) {
    isDuplicate = true;
    duplicatedMessage = similarity[0][1];
  }

  if (!isDuplicate) return <></>;

  return (
    <div className="border p-2 rounded border-yellow-500">
      <BaseSmall className="inline-flex flex-row items-center text-yellow-500">
        <ExclamationTriangleIcon className="w-4 h-4 mr-1" /> This message may be
        a duplicate of a previous messages in this thread.
      </BaseSmall>
      <br />
      <Info className="whitespace-pre-wrap">
        {duplicatedMessage?.replace(/^(.{250}).+$/gms, "$1...")}
      </Info>
    </div>
  );
};
