import { Tag } from "@atoms/badge/tag";
import { Page } from "@atoms/layout/page";
import {
  PageBlock,
  PageBlockHr,
  PageResponsiveColumns,
} from "@atoms/layout/page-block";
import { Base, Info, Section, SectionSmall, Title } from "@atoms/text";
import Assignments from "@components/assignations";
import { PageLoader } from "@components/page-loader";
import { RiskFactorTable } from "@components/risk-factors-table";
import { RestSearchRequest } from "@components/search-bar/utils/types";
import { Table } from "@components/table";
import { useHasAccess } from "@features/auth/state/use-access";
import { useCustomersTransactions } from "@features/kyt/state/use-customers-transactions";
import { useTransaction } from "@features/kyt/state/use-transaction";
import { KytTransaction } from "@features/kyt/types";
import {
  KYT_TRANSACTION_STATES_COLORS,
  KYT_TRANSACTION_STATES_LABELS,
} from "@features/kyt/utils";
import { useRiskFactors } from "@features/risk-decisions/use-risks-decisions";
import { formatTime } from "@features/utils/dates";
import _ from "lodash";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { availableColumns, defaultColumns } from "../columns";
import TransactionAmount from "../components/transaction-amount";
import TransactionParticipant from "../components/transaction-participant";
import { TransactionNavigation } from "./navigation";
import TransactionTimeline from "./timeline";
import { TransactionFields } from "./transaction-fields";

export default function TransactionDetailsPage() {
  const { id } = useParams();
  const { transaction, revisions, loading, from, to } = useTransaction(
    id ?? ""
  );
  const { riskFactors } = useRiskFactors("kyt");

  const [otherTransactionsPagination, setOtherTransactionsPagination] =
    useState<RestSearchRequest["options"]>({ limit: 10, offset: 0 });
  const { transactions, loading: loadOtherTransactions } =
    useCustomersTransactions(
      [transaction?.from?.external_id, transaction?.to?.external_id].filter(
        Boolean
      ) as string[],
      otherTransactionsPagination
    );

  const hasAccess = useHasAccess();
  const [viewMore, setViewMore] = useState(false);

  return (
    <Page>
      <Title>
        <div className="flex flex-row items-center">
          <div className="grow flex items-center">
            <span>Transaction "{transaction?.external_id}"</span>
            {transaction?.state !== undefined && (
              <Tag
                noColor
                className={
                  "bg-" +
                  KYT_TRANSACTION_STATES_COLORS[transaction.state] +
                  " text-white ml-2"
                }
              >
                {KYT_TRANSACTION_STATES_LABELS[transaction.state]}
              </Tag>
            )}
          </div>
          <TransactionNavigation id={id!} />
        </div>
      </Title>
      {!transaction && loading && (
        <div style={{ marginTop: "30vh" }}>
          <PageLoader />
        </div>
      )}
      {!transaction && !loading && <Info>This transaction was not found.</Info>}
      {transaction && (
        <>
          <div className="grid grid-cols-2 gap-4 mt-5">
            <TransactionParticipant
              id={from?.data?.id}
              participant={transaction.from}
              full={viewMore}
              direction="from"
              className="border-l-2 border-l-blue-500"
              onShowMore={(viewMore) => setViewMore(viewMore)}
            />
            <TransactionParticipant
              id={to?.data?.id}
              participant={transaction.to}
              full={viewMore}
              direction="to"
              className="border-l-2 border-l-red-500"
              onShowMore={(viewMore) => setViewMore(viewMore)}
            />
          </div>

          <div className="flex flex-row space-x-10">
            <div className="w-1/4 flex flex-col mt-4">
              <Assignments
                type="transaction"
                id={transaction.id}
                groups={transaction.review_groups ?? []}
                readonly={!hasAccess("KYT_MANAGE")}
              />
              <TransactionTimeline
                transaction={transaction}
                revisions={revisions ?? []}
              />
            </div>
            <div className="grow flex flex-col">
              <Section className="mt-4">Transaction details</Section>
              <PageBlock>
                <PageResponsiveColumns>
                  <div className="grow">
                    <Info className="mt-4">Amount</Info>
                    <TransactionAmount transaction={transaction} />
                  </div>
                  <div className="grow">
                    <Info>Date</Info>
                    <br />
                    <Base>
                      {formatTime(transaction.date, undefined, {
                        keepDate: true,
                        keepTime: true,
                      })}
                    </Base>
                  </div>
                </PageResponsiveColumns>
                <PageBlockHr />
                <PageResponsiveColumns>
                  <div className="grow w-2/3 shrink-0">
                    <Info>Label</Info>
                    <br />
                    <Base>{transaction.label || "-"}</Base>
                  </div>
                  <div className="w-1/3 shrink-0">
                    <Info>Context</Info>
                    <br />
                    <Base>{transaction.context || "-"}</Base>
                  </div>
                </PageResponsiveColumns>
                <PageBlockHr />
                <div className="flex flex-row mb-1">
                  <SectionSmall>Transaction Scoring</SectionSmall>
                  <div className="grow" />
                  <Tag
                    className={
                      "text-white block px-3 py-2 mr-2 text-sm bg-" +
                      KYT_TRANSACTION_STATES_COLORS[transaction.state]
                    }
                  >
                    {transaction.score}%
                  </Tag>
                  <div>
                    <Tag
                      noColor
                      className={
                        "text-white block px-3 py-2 text-sm bg-" +
                        KYT_TRANSACTION_STATES_COLORS[transaction.state]
                      }
                    >
                      {KYT_TRANSACTION_STATES_LABELS[transaction.state] ||
                        "Undefined"}
                    </Tag>
                  </div>
                </div>
                <RiskFactorTable
                  factors={riskFactors.map(
                    (a) =>
                      ({
                        ...a,
                        score: (transaction.rules || []).find(
                          (b) => b.code === a.code
                        )?.score,
                      } as any)
                  )}
                />

                {!!(
                  transaction.crypto?.blockchain || transaction.crypto?.tx_hash
                ) && (
                  <>
                    <PageBlockHr />
                    <SectionSmall>Blockchain</SectionSmall>
                    <Base>
                      <Info>Blockchain: </Info>
                      {transaction.crypto?.blockchain ?? "-"}
                    </Base>
                    <br />
                    <Base>
                      <Info>Transaction hash: </Info>
                      {transaction.crypto?.tx_hash ?? "-"}
                    </Base>
                  </>
                )}

                {transaction.fields && (
                  <TransactionFields fields={transaction.fields} />
                )}
              </PageBlock>
              <Section className="mt-4">More transactions</Section>
              {!!transactions?.total && (
                <PageBlock>
                  <Table
                    totalApproximate
                    rowIndex="external_id"
                    total={transactions?.total ?? 0}
                    initialPagination={{
                      page: otherTransactionsPagination.offset
                        ? otherTransactionsPagination.offset /
                            (otherTransactionsPagination.limit || 10) +
                          1
                        : 1,
                      perPage: otherTransactionsPagination.limit || 10,
                    }}
                    onRequestData={async (pagination) => {
                      setOtherTransactionsPagination({
                        limit: pagination.perPage,
                        offset: (pagination.page - 1) * pagination.perPage,
                      });
                    }}
                    data={transactions?.data || []}
                    columns={defaultColumns.map(
                      (col) =>
                        availableColumns[col] || {
                          title: col,
                          orderable: true,
                          className: "text-right",
                          headClassName: "justify-end",
                          thClassName: "w-24",
                          render: (row: KytTransaction) => _.get(row, col),
                        }
                    )}
                    loading={loadOtherTransactions}
                  />
                </PageBlock>
              )}
            </div>
          </div>
        </>
      )}
    </Page>
  );
}
