import { ButtonConfirm } from "@atoms/button/confirm";
import { InputLabel } from "@atoms/input/input-decoration-label";
import { Input } from "@atoms/input/input-text";
import { Modal, ModalContent } from "@atoms/modal/modal";
import Select from "@atoms/select";
import { SessionsApiClient } from "@features/sessions/api-client/api-client";
import { useSessionDecisions } from "@features/sessions/state/use-session-decisions";
import { useSessionStates } from "@features/sessions/state/use-sessions-states";
import { ReactNode, useState } from "react";
import toast from "react-hot-toast";
import { atom, useRecoilState } from "recoil";

export const SessionDecisionAddModalAtom = atom<{
  open: boolean;
  comment: string;
  status: number | "disabled";
  annotated: { [key: string]: string };
  session_id: string;
  children?: ReactNode;
  handleSubmit?: () => void;
}>({
  key: "SessionDecisionAddModalAtom",
  default: {
    open: false,
    comment: "",
    annotated: {},
    status: 0,
    session_id: "",
  },
});

export default function SessionDecisionAddModal() {
  const [modal, setModal] = useRecoilState(SessionDecisionAddModalAtom);
  return (
    <Modal
      open={modal.open}
      onClose={() =>
        setModal({
          open: false,
          comment: "",
          status: 0,
          session_id: "",
          annotated: {},
        })
      }
    >
      {modal.open && <SessionDecisionAddModalContent />}
    </Modal>
  );
}

const SessionDecisionAddModalContent = () => {
  const { states } = useSessionStates();
  const [modal, setModal] = useRecoilState(SessionDecisionAddModalAtom);
  const { refresh } = useSessionDecisions(modal.session_id);
  const [loading, setLoading] = useState(false);
  return (
    <ModalContent title="Add Decision">
      <InputLabel
        label="Status"
        input={
          <Select
            value={modal.status || ""}
            onChange={(e) =>
              setModal({
                ...modal,
                status: e.target.value as number | "disabled",
              })
            }
          >
            <option>Select new status</option>
            {states.map((status) => (
              <option key={"review_" + status.id} value={status.id}>
                {status.label}
              </option>
            ))}
          </Select>
        }
      />

      <InputLabel
        className="mt-4"
        label="Comment"
        input={
          <Input
            placeholder="Comment"
            multiline
            size="sm"
            value={modal.comment}
            onChange={(e) => {
              setModal({ ...modal, comment: e.target.value });
            }}
          />
        }
      />
      <div className="flex flex-row items-center justify-between mt-6">
        <ButtonConfirm
          confirmTitle="Save Session Decision"
          className="float-right"
          theme="primary"
          disabled={!modal.status || !modal.session_id || !modal.comment}
          loading={loading}
          onClick={async () => {
            try {
              setLoading(true);
              modal?.handleSubmit && modal.handleSubmit();
              await SessionsApiClient.createSessionDecision(
                modal.session_id,
                modal.status as number,
                modal.comment,
                JSON.stringify(modal.annotated)
              );
              toast.success("Session Decision added successfully");
              setModal({
                open: false,
                status: 0,
                comment: "",
                session_id: "",
                annotated: {},
              });
            } catch (error) {
              toast.error("Error adding Session Decision");
              setModal({ ...modal, open: false });
            } finally {
              refresh();
              setLoading(false);
            }
          }}
        >
          Save
        </ButtonConfirm>
        {modal.children}
      </div>
    </ModalContent>
  );
};
