import { SessionDecisionType } from "@features/sessions/types";
import { createContext, useContext } from "react";

export type FormAnnotated = { [key: string]: string };

export type AnnotatedInfos = {
  agent_name: string;
  annotated: FormAnnotated;
  date: string;
};

interface StateAnnotationsProvider {
  ressourceID: string;
  decisions: SessionDecisionType[];
  formAnnotated: FormAnnotated;
  listAnnotated: AnnotatedInfos[];
  addElement: (item: FormAnnotated) => void;
  resetFormAnnotated: () => void;
}

export const AnnotationContext = createContext<
  StateAnnotationsProvider | undefined
>(undefined);

export const useAnnotationList = () => {
  const context = useContext(AnnotationContext);
  if (!context) {
    throw new Error("useList must be usedwithin a ListProvider");
  }
  return context;
};
