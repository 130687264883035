import Card from "@atoms/card";
import { Page } from "@atoms/layout/page";
import { PageBlock, PageResponsiveColumns } from "@atoms/layout/page-block";
import { FloatingTabs } from "@atoms/tabs";
import { Section, SectionSmall, Title } from "@atoms/text";
import Assignments from "@components/assignations";
import { PageLoader } from "@components/page-loader";
import { useHasAccess } from "@features/auth/state/use-access";
import { useCustomer } from "@features/customers/state/use-customer";
import { extractCustomerFullName } from "@features/customers/utils";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ComputedFields } from "./computed";
import { CustomFields, hasCustomFields } from "./custom-fields";
import CustomerSummary from "./customer-summary";
import { DocumentManager } from "./document-manager";
import { Notes } from "./document-manager/notes";
import { Identity } from "./identity";
import { SharedProductsMenu } from "./menu";
import { CustomerNavigation } from "./navigation";
import { useOlkyPassSpecifics } from "./olkypass";
import RelationsGraph from "./relations-graph";
import {
  AlertsReview,
  ChatReview,
  KYTReview,
  OnboardingReview,
  PressReview,
  RiskReview,
} from "./review";
import { CustomerTimeline } from "./timeline";
import { Button } from "@atoms/button/button";
import { CustomerEditionModalAtom } from "./edition-form";
import { useSetRecoilState } from "recoil";

export const CustomersDetailsPage = () => {
  const { id } = useParams();
  const setOpenCreateModal = useSetRecoilState(CustomerEditionModalAtom);
  const { customer, loading, refresh } = useCustomer(id || "");
  const hasAccess = useHasAccess();
  const [tabValue, setTabValue] = useState("kyc");

  useEffect(() => {
    refresh();
  }, [id, refresh]);

  const { use: useOlkypass } = useOlkyPassSpecifics(
    id!,
    customer?.details?.custom_fields || []
  );

  const hasDataFields = hasCustomFields(customer!, "none");
  const hasFinancialFields = hasCustomFields(customer!, "financial");
  const hasChatFields = hasCustomFields(customer!, "go!chat");

  if (!customer?.details?.customer) {
    return (
      <Page>
        <Title className="flex items-center">
          <div className="grow">Details for {id}</div>
        </Title>
        <PageLoader />
      </Page>
    );
  }

  let subIndex = 1;

  return (
    <Page>
      <Title className="flex items-center">
        <div className="grow">
          Details for{" "}
          {customer ? extractCustomerFullName(customer.details.customer) : ""}
        </div>
        <CustomerNavigation id={id!} />
      </Title>
      <SharedProductsMenu id={id!} />

      {!customer && loading && (
        <div style={{ marginTop: "30vh" }}>
          <PageLoader />
        </div>
      )}

      {customer && (
        <>
          <CustomerSummary customer={customer} />

          {customer.details.customer.disabled && (
            <Card
              className="bg-slate-400 mt-4"
              text={
                <div className="text-white text-center flex flex-row items-center justify-center">
                  <InformationCircleIcon className="h-5 w-5 inline mr-2" />
                  This customer is disabled, it cannot be modified and is only
                  visible for audit purposes.
                </div>
              }
            />
          )}

          <div className="justify-between mt-2">
            <PageResponsiveColumns>
              <div className="shrink-0 md:w-1/4 md:max-w-sm w-full">
                <Assignments
                  type="customer"
                  id={customer.details.customer.id}
                  groups={customer.details.customer.review_groups || []}
                  readonly={!hasAccess("CUSTOMER_UPDATE")}
                />
                <CustomerTimeline
                  disabled={customer.details.customer.disabled}
                  revisions={customer.edd_history.edd_revisions}
                />
              </div>
              <div className="grow">
                <FloatingTabs
                  value={tabValue}
                  onChange={(e) => setTabValue(e as string)}
                  tabs={[
                    {
                      value: "kyc",
                      label: useOlkypass ? "OLKYPASS" : "KYC",
                    },
                    ...(hasAccess("KYT")
                      ? [
                          {
                            value: "gokyt",
                            label: "KYT",
                          },
                        ]
                      : []),
                    ...(hasAccess("DASHBOARD_RISK_SCORING")
                      ? [
                          {
                            value: "gorisk",
                            label: "RISK",
                          },
                        ]
                      : []),
                    ...(hasAccess("CHAT")
                      ? [
                          {
                            value: "gochat",
                            label: "CHAT",
                          },
                        ]
                      : []),
                    {
                      value: "data",
                      label: "DATA",
                    },
                  ]}
                >
                  {hasAccess("CUSTOMER_UPDATE") && (
                    <Button
                      className="ml-2 "
                      disabled={customer?.details.customer.disabled}
                      onClick={() =>
                        setOpenCreateModal({
                          open: true,
                          customer: customer?.details,
                        })
                      }
                      size="sm"
                    >
                      Edit Customer
                    </Button>
                  )}
                </FloatingTabs>
                {tabValue === "kyc" && (
                  <>
                    <div className="mt-4" />
                    <Identity customer={customer} edit />
                    <Section className="mt-8">
                      I - Additionnal identity features
                    </Section>
                    <PageBlock className="my-2">
                      <CustomFields
                        customer={customer}
                        displayByRelatedProduct="identity"
                      />{" "}
                    </PageBlock>

                    <Section className="mt-8">II - Identity Checks</Section>
                    {hasAccess("SIDENAV_SESSION") && (
                      <>
                        <OnboardingReview
                          customer={customer}
                          olkypass={useOlkypass}
                        />
                      </>
                    )}

                    <Section className="mt-8">
                      III - Other client information
                    </Section>
                    <div className="space-y-6">
                      <div className="space-y-2">
                        <SectionSmall className="mt-4">
                          III.{subIndex++} - AML summary
                        </SectionSmall>{" "}
                        {hasAccess("RISK_SETTINGS") && (
                          <PageBlock>
                            <ComputedFields customer={customer} />
                          </PageBlock>
                        )}
                        {hasAccess("SIDENAV_ALERT") && (
                          <AlertsReview customer={customer} />
                        )}
                        {hasAccess("PRESS") && (
                          <PressReview customer={customer} />
                        )}
                        {hasAccess("CUSTOMER_READ") && (
                          <>
                            <PageBlock className="mb-2">
                              <div className="-m-4">
                                <RelationsGraph customer={customer} />
                              </div>
                              <div className="mt-2" />
                            </PageBlock>
                          </>
                        )}
                      </div>
                      {hasAccess("KYT") && hasFinancialFields && (
                        <div className="space-y-2">
                          <SectionSmall className="mt-4">
                            III.{subIndex++} - Financial information
                          </SectionSmall>
                          <PageBlock className="mt-2 !py-3">
                            <CustomFields
                              customer={customer}
                              displayByRelatedProduct="financial"
                            />
                          </PageBlock>
                        </div>
                      )}
                      {hasAccess("CUSTOMER_READ") && (
                        <div className="space-y-2">
                          <SectionSmall className="mt-4">
                            III.{subIndex++} - Documents and notes
                          </SectionSmall>
                          <PageBlock className="mb-2">
                            <Notes customer={customer} />
                            <hr className="my-4 -mx-4" />
                            <SectionSmall className="mt-4">
                              Documents
                            </SectionSmall>
                            <div className="mt-2" />
                            <DocumentManager customer={customer} />
                          </PageBlock>
                        </div>
                      )}
                    </div>
                  </>
                )}
                {tabValue === "gokyt" && (
                  <>
                    <Section className="mt-4">Transactions</Section>
                    {hasAccess("KYT") && (
                      <div className="mt-2">
                        <KYTReview customer={customer} />
                      </div>
                    )}
                  </>
                )}
                {tabValue === "gorisk" && (
                  <>
                    <Section className="mt-4">Risk score</Section>
                    {hasAccess("DASHBOARD_RISK_SCORING") && (
                      <div className="mt-2">
                        <RiskReview customer={customer} />
                      </div>
                    )}
                  </>
                )}
                {tabValue === "gochat" && (
                  <>
                    <Section className="mt-4">Exchanges with customer</Section>
                    <ChatReview customer={customer} />
                    {hasChatFields && (
                      <PageBlock className="mt-2 !py-3">
                        <CustomFields
                          customer={customer}
                          displayByRelatedProduct="go!chat"
                        />
                      </PageBlock>
                    )}
                    <Section className="mt-4">
                      Notes about other exchanges
                    </Section>
                    <PageBlock className="mb-2">
                      <Notes customer={customer} tags={["gochat"]} />
                    </PageBlock>
                  </>
                )}
                {tabValue === "data" && (
                  <>
                    {hasDataFields && (
                      <>
                        <Section className="mt-4">Additional data</Section>
                        <PageBlock className="mt-2 !py-3">
                          <CustomFields
                            customer={customer}
                            displayByRelatedProduct="none"
                          />
                        </PageBlock>
                      </>
                    )}
                    {hasAccess("CUSTOMER_READ") && (
                      <>
                        <Section className="mt-4">Documents and notes</Section>
                        <PageBlock className="mb-2">
                          <Notes customer={customer} />
                          <hr className="my-4 -mx-4" />
                          <SectionSmall className="mt-4">
                            Documents
                          </SectionSmall>
                          <div className="mt-2" />
                          <DocumentManager customer={customer} />
                        </PageBlock>
                      </>
                    )}
                  </>
                )}
              </div>
            </PageResponsiveColumns>
          </div>
        </>
      )}
    </Page>
  );
};
