import { Tag } from "@atoms/badge/tag";
import { PageBlock, PageBlockHr } from "@atoms/layout/page-block";
import Link from "@atoms/link";
import { Base, Info, SectionSmall } from "@atoms/text";
import { ModalImage } from "@components/modal-image";
import { Table } from "@components/table";
import { PressReportBody, ReportCategory } from "@features/press/types";
import {
  CATEGORY_DEGREE_OF_CERTAINTY,
  CATEGORY_SEVERITY,
  CERTAINTY_COLORS,
  SEVERITY_COLORS,
} from "@features/press/utils";
import _, { upperFirst } from "lodash";
import { Fragment } from "react";
import { twMerge } from "tailwind-merge";

export const PressReportBodyDetailsPage = ({
  reportBody,
}: {
  reportBody: PressReportBody;
}) => {
  const images = _.uniq(
    _.flattenDeep(
      (reportBody?.general_sources || []).map(
        (source) => source.website.images || []
      )
    )
  );

  const reportCategories = (reportBody.report_categories || []).filter(
    (a) => !a.reverted
  );

  const sortedReportCategories = _.sortBy(
    reportCategories,
    (row) =>
      [
        CATEGORY_SEVERITY.HIGH,
        CATEGORY_SEVERITY.MEDIUM,
        CATEGORY_SEVERITY.LOW,
      ].indexOf(row.severity),
    (row) =>
      [
        CATEGORY_DEGREE_OF_CERTAINTY.CONFIRMED,
        CATEGORY_DEGREE_OF_CERTAINTY.LIKELY,
        CATEGORY_DEGREE_OF_CERTAINTY.UNSURE,
      ].indexOf(row.degree_of_certainty)
  );

  return (
    <div className="space-y-4">
      {reportCategories.length > 0 && (
        <div className="my-2">
          <Info className="inline-block">
            Risk categories (details below):{" "}
          </Info>
          {sortedReportCategories.map((srp, i) => (
            <Tag
              noColor
              key={i}
              className={twMerge(
                "text-white",
                "mx-1",
                "bg-" + SEVERITY_COLORS[srp.severity]
              )}
            >
              {srp.category}
            </Tag>
          ))}
        </div>
      )}
      <PageBlock>
        {images.length > 0 && (
          <>
            <div className="w-full space-x-2 overflow-auto pt-2 -mx-2 whitespace-nowrap">
              {images.map((img) => (
                <div className="inline-block" key={img}>
                  <img
                    alt={img}
                    src={img}
                    onError={(e) => {
                      if (e.currentTarget.parentNode) {
                        (e.currentTarget.parentElement as any).style.margin =
                          "0";
                        (e.currentTarget.parentNode as any).innerHTML = "";
                      }
                    }}
                    className="hidden"
                  />
                  <ModalImage
                    className="rounded h-32 w-auto inline-block bg-slate-200 dark:bg-slate-600"
                    small={img}
                    large={img}
                    alt=""
                  />
                </div>
              ))}
            </div>
            <PageBlockHr />
          </>
        )}
        {!!reportBody.namesake?.historical_or_dead_entity && (
          <>
            <Tag noColor className="bg-neutral-500 text-white mr-2">
              Historical or dead entity
            </Tag>
            <Info>
              This report is probably about a historical or dead entity and is
              not relevant.
            </Info>
            <PageBlockHr />
          </>
        )}
        {reportBody.namesake?.score > 0 && (
          <>
            <Tag noColor className="bg-red-500 text-white mr-2">
              Namesake {reportBody.namesake?.score}%
            </Tag>
            <Info>
              This report could be about a different person with the same name.
            </Info>
            <PageBlockHr />
          </>
        )}
        {reportBody.namesake?.count > 1 && (
          <>
            <Tag noColor className="bg-red-500 text-white mr-2">
              {">="} {reportBody.namesake?.count} namesakes
            </Tag>
            <Info>We found multiple people with the same name.</Info>
            <PageBlockHr />
          </>
        )}
        <SectionSmall className="my-1 mt-4">General summary</SectionSmall>
        <PressSummary text={reportBody.general_summary} />
        {reportBody.risk_summary && reportCategories.length > 0 && (
          <>
            <PageBlockHr />
            <SectionSmall className="my-1">Risk summary</SectionSmall>
            <PressSummary text={reportBody.risk_summary} />
          </>
        )}
      </PageBlock>
      {reportCategories.length > 0 && (
        <PageBlock>
          <SectionSmall className="my-1">Categories</SectionSmall>

          <Table
            data={sortedReportCategories}
            showPagination={false}
            columns={[
              {
                title: "Category",
                className: "overflow-hidden text-ellipsis",
                thClassName: "w-40",
                render: (row) => (
                  <div className="flex flex-row whitespace-nowrap">
                    <Tag className="rounded-r-none">{row.category}</Tag>
                    <Tag
                      noColor
                      className={twMerge(
                        "text-white rounded-l-none",
                        "bg-" + CERTAINTY_COLORS[row.degree_of_certainty]
                      )}
                    >
                      {upperFirst(row.degree_of_certainty)}
                    </Tag>
                  </div>
                ),
              },
              {
                title: "Severity",
                render: (row) => (
                  <Tag
                    noColor
                    className={twMerge(
                      "text-white",
                      "bg-" + SEVERITY_COLORS[row.severity]
                    )}
                  >
                    {row.severity}
                  </Tag>
                ),
              },
              {
                title: "Reason",
                render: (row) => (
                  <div className="flex items-center">
                    <PressSummary text={row.reason} />
                  </div>
                ),
              },
            ]}
          />
        </PageBlock>
      )}
      <PageBlock className="space-y-4">
        {[
          ...reportCategories,
          {
            category: "General",
            sources: reportBody.general_sources,
          } as Partial<ReportCategory>,
        ].map((a, i) => (
          <div key={a.category} className="space-y-2">
            {i !== 0 && <PageBlockHr />}
            <SectionSmall className="my-1">{a.category}</SectionSmall>
            {!a.sources?.length && <Info>No sources</Info>}
            {a.sources?.map((b) => (
              <div
                key={b.website.url}
                className="flex flex-col rounded"
                id={"source-" + b.website.index}
              >
                <Link href={b.website.url} target={"_blank"}>
                  <Info className="flex-1" noColor>
                    [{b.website.index}] {b.website.title}
                    <span className="opacity-50">
                      {" - "}
                      {b.website.url}
                    </span>
                  </Info>
                </Link>
                <Base>{b.website.snippet}</Base>
                <Base>{b.website.summary}</Base>
              </div>
            ))}
          </div>
        ))}
      </PageBlock>
    </div>
  );
};

const PressSummary = ({ text }: { text: string }) => {
  // Detect [1, 12] or (1, 12) or (5) or [6] and convert to links
  const putQuotes = (text: string) => {
    const matches = text.match(/(\[|\() *\d+ *(, *\d+ *)*(\]|\))/gm) || [];

    if (!matches.length) return text;

    const currentText = [];
    let remainingText = text;
    for (const match of matches) {
      const parts = (remainingText || "").split(match);
      currentText.push(parts[0]);
      const matchedIndexes = match.match(/\d+/gm);
      if (matchedIndexes) {
        currentText.push(
          <a
            key={currentText.length}
            onClick={() => {
              const element = document.getElementById(
                "source-" + matchedIndexes[0]
              );
              if (element) {
                element.classList.add(
                  "bg-yellow-100",
                  "transition-all",
                  "duration-1000"
                );
                element.scrollIntoView({ behavior: "smooth" });
                setTimeout(() => {
                  element.classList.remove("bg-yellow-100");
                }, 2000);
              }
            }}
          >
            <sup className="text-sm text-blue-500">
              (
              {_.uniq(matchedIndexes || []).map((index, i) => (
                <Fragment key={index}>
                  {i !== 0 && ", "}
                  <Link key={currentText.length}>{index}</Link>
                </Fragment>
              ))}
              )
            </sup>
          </a>
        );
      }
      remainingText = parts[1] || "";
    }

    return [...currentText, remainingText];
  };

  // convert \n to <br>
  return text
    .replace(
      // Replace any tags
      /<[^>]*>/g,
      ""
    )
    .split("\n")
    .map((line, i) => {
      // Put *xxx* in bold
      const parts = line.split("*");
      return (
        <Fragment key={i}>
          <Base key={i} className="block mb-2">
            {parts.map((part, i) => (
              <Fragment key={i}>
                {i % 2 === 0 ? putQuotes(part) : <b>{putQuotes(part)}</b>}
              </Fragment>
            ))}
          </Base>
        </Fragment>
      );
    }) as any;
};
