import countries from "@assets/countries.json";
import { Tag } from "@atoms/badge/tag";
import { Button } from "@atoms/button/button";
import Link from "@atoms/link";
import { Info, SectionSmall } from "@atoms/text";
import { useHasAccess } from "@features/auth/state/use-access";
import { CustomerAllDetailType } from "@features/customers/types";
import { copyToClipboard } from "@features/utils";
import { formatTime } from "@features/utils/dates";
import {
  ClipboardDocumentIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";

import { PageBlock } from "@atoms/layout/page-block";
import { useSetRecoilState } from "recoil";
import { CustomerEditionModalAtom } from "./edition-form";
import { EventsTimelineModalAtom } from "./events-timeline-modal";
import { twMerge } from "tailwind-merge";

export const Identity = ({
  customer,
  edit,
}: {
  customer: CustomerAllDetailType;
  edit?: boolean;
}) => {
  const setOpenCreateModal = useSetRecoilState(CustomerEditionModalAtom);
  const setTimelineModal = useSetRecoilState(EventsTimelineModalAtom);
  const hasAccess = useHasAccess();

  const baseCustomer = customer?.details.customer;
  return (
    <>
      <PageBlock className="my-2">
        {edit && hasAccess("CUSTOMER_UPDATE") && (
          <Button
            className="float-right mt-1 ml-2"
            disabled={baseCustomer.disabled}
            onClick={() =>
              setOpenCreateModal({
                open: true,
                customer: customer?.details,
              })
            }
            size="sm"
          >
            Edit Customer
          </Button>
        )}
        <Button
          className="float-right mt-1 border-none"
          onClick={() => {
            copyToClipboard(
              [
                baseCustomer.first_name
                  ? `First Name: ${baseCustomer.first_name}`
                  : "",
                baseCustomer.middle_name
                  ? `Middle Name: ${baseCustomer.middle_name}`
                  : "",
                baseCustomer.last_name
                  ? `Last Name: ${baseCustomer.last_name}`
                  : "",
                baseCustomer.date_of_birth
                  ? `Date of birth: ${
                      new Date(baseCustomer.date_of_birth)
                        .toISOString()
                        .split("T")[0]
                    }`
                  : " ",
                baseCustomer.company_name
                  ? `Company Name: ${baseCustomer.company_name}`
                  : "",
                baseCustomer.trading_name
                  ? `Trading Name: ${baseCustomer.trading_name}`
                  : "",
                baseCustomer.registration_number
                  ? `Registration Number: ${baseCustomer.registration_number}`
                  : "",
                baseCustomer.nationality_code
                  ? `Nationality: ${baseCustomer.nationality_code}`
                  : "",
                baseCustomer.trading_name
                  ? `Domicile: ${baseCustomer.domicile_code}`
                  : "",
                baseCustomer.registration_number
                  ? `Registration Number: ${baseCustomer.registration_number}`
                  : "",
                " ",
                baseCustomer.external_id
                  ? `External ID: ${baseCustomer.external_id}`
                  : "",
              ]
                .filter((a) => a)
                .join(`\n`)
            );
          }}
          theme="outlined"
          size="sm"
        >
          <span data-tooltip="Copy identity to clipboard">
            <ClipboardDocumentIcon className="w-6 h-6" />
          </span>
        </Button>
        <div className="flex flex-row justify-between">
          <SectionSmall className="mt-2 mb-2">Base information</SectionSmall>
        </div>
        <div className="flex flex-row mt-2">
          <Names type={baseCustomer.customer_type} names={baseCustomer} />
        </div>
        {baseCustomer?.name_variations?.length > 0 && (
          <>
            <Info className="block mt-2">Name variations</Info>

            {baseCustomer?.name_variations?.map((variation, i) => (
              <div key={i} className="flex flex-row">
                <Names
                  type={baseCustomer.customer_type}
                  names={{ ...baseCustomer, ...variation }}
                  variation={false}
                />
              </div>
            ))}
            <hr className="my-4 -mx-4" />
          </>
        )}
        <div className="flex flex-row mt-4">
          <div className="w-1/4">
            <Info>Nationality</Info>
            <br />
            {baseCustomer?.nationality_code ? (
              <>
                {countries.find(
                  (c) => c["alpha-3"] === baseCustomer?.nationality_code
                )?.name || "Invalid"}{" "}
                <Info>({baseCustomer?.nationality_code})</Info>
              </>
            ) : (
              "-"
            )}{" "}
          </div>
          <div className="w-1/4">
            <Info>Domicile</Info>
            <br />
            {baseCustomer?.domicile_code ? (
              <>
                {" "}
                {countries.find(
                  (c) => c["alpha-3"] === baseCustomer?.domicile_code
                )?.name || "Invalid"}{" "}
                <Info>({baseCustomer?.domicile_code})</Info>{" "}
              </>
            ) : (
              "-"
            )}
          </div>
        </div>
        <div className="flex flex-row mt-4">
          <div className="w-1/2">
            <Info>Contacts</Info>
            <br />
            <div className="overflow-hidden mr-2 text-base">
              {baseCustomer?.contacts?.length
                ? baseCustomer?.contacts
                    .split(/,|;/g)
                    .map((a) => a.trim())
                    .filter((a) => a)
                    .map((a, i) => (
                      <Link
                        key={i}
                        className="mr-2"
                        href={`mailto:${a.trim()}`}
                      >
                        {a.split(" ")?.[1]?.replace(/(^<|>$)/gm, "") || a}
                      </Link>
                    ))
                : "-"}
            </div>
          </div>
          <div className="w-1/4">
            <Info>Phone</Info>
            <br />
            <div className="overflow-hidden mr-2 text-base">
              {baseCustomer?.phone || "-"}
            </div>
          </div>
        </div>
        <div className="flex flex-row mt-4">
          {baseCustomer?.registration_number && (
            <div className="w-1/4">
              <Info>Registration Number</Info>
              <br />
              <div className="overflow-hidden mr-2 text-base">
                {baseCustomer?.registration_number}
              </div>
            </div>
          )}
          <div className="w-1/4">
            <Info>External ID</Info>
            <br />
            <div className="overflow-hidden mr-2 text-base">
              {baseCustomer?.external_id}
            </div>
          </div>
          {baseCustomer?.alias_ids && (
            <div className="w-1/4">
              <Info>Alias IDs</Info>
              <br />
              <div className="overflow-hidden mr-2 text-base">
                {baseCustomer?.alias_ids.map((a) => (
                  <>
                    {a}
                    <br />
                  </>
                ))}
              </div>
            </div>
          )}
          <div className="w-1/4">
            <Info>Created</Info>
            <br />
            {formatTime(
              customer.edd_history.edd_revisions[
                customer.edd_history.edd_revisions.length - 1
              ]?.created_at || baseCustomer?.created_at
            )}
          </div>

          <div className="w-1/4">
            <Info>Last revision</Info>
            <br />
            <div className="inline-flex items-center space-x-2">
              <span>{formatTime(baseCustomer?.created_at)}</span>
              <Info>•</Info>
              <Info>
                {parseInt(baseCustomer?.revision_number || "0") >= 5 ? (
                  <Tag
                    noColor
                    className={twMerge(
                      "text-white bg-red-500 inline-flex items-center"
                    )}
                  >
                    <ExclamationTriangleIcon className="w-4 h-4 mr-1" />
                    {baseCustomer?.revision_number} revisions
                  </Tag>
                ) : (
                  <Tag
                    noColor
                    className={twMerge(
                      "text-white bg-slate-400 inline-flex items-center"
                    )}
                  >
                    {baseCustomer?.revision_number} revision
                  </Tag>
                )}
              </Info>
            </div>
            <br />
            <Info>
              <Link
                onClick={() =>
                  setTimelineModal({ customer_id: baseCustomer.id })
                }
              >
                Open revisions
              </Link>
            </Info>
          </div>
        </div>
      </PageBlock>
    </>
  );
};

const Names = ({
  type,
  names,
  variation,
}: {
  type: string;
  names: {
    first_name: string;
    middle_name: string;
    last_name: string;
    date_of_birth: string;
    company_name: string;
    trading_name: string;
  };
  variation?: boolean;
}) => {
  variation = variation === false ? false : true;

  return (
    <>
      {type !== "3" && (
        <>
          <div className="w-1/4">
            {variation && (
              <>
                <Info>First name</Info>
                <br />
              </>
            )}

            {names?.first_name || "-"}
          </div>
          <div className="w-1/4">
            {variation && (
              <>
                <Info>Middle name</Info>
                <br />
              </>
            )}

            {names?.middle_name || "-"}
          </div>
          <div className="w-1/4">
            {variation && (
              <>
                <Info>Last name</Info>
                <br />
              </>
            )}

            {names?.last_name || "-"}
          </div>
          <div className="w-1/4">
            {variation && (
              <>
                <Info>Date of birth</Info>
                <br />
              </>
            )}

            {(names?.date_of_birth
              ? new Date(names?.date_of_birth).toISOString().split("T")[0]
              : null) || "Not defined"}
          </div>
        </>
      )}
      {type === "3" && (
        <>
          <div className="w-1/2">
            {variation && (
              <>
                <Info>Company name</Info>
                <br />
              </>
            )}
            {names?.company_name || "-"}
          </div>
          <div className="w-1/2">
            {variation && (
              <>
                <Info>Trading name</Info>
                <br />
              </>
            )}
            {names?.trading_name || "-"}
          </div>
        </>
      )}
    </>
  );
};
