import countryLocalesMap from "@assets/countries-locales.json";
import DOMPurify from "dompurify";

export const validateEmail = (email: string) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export const htmlDecodeRawTextContent = (input: string) => {
  input = input.replace(/<\/p>|<\/blockquote>|<\/li>/gm, "\n$&"); // due to our editor using these html tags for newline, add classic \n newline to render raw text content
  const doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
};

export const htmlParseAndSecure = (input: string) => {
  const doc = new DOMParser().parseFromString(input, "text/html");
  const sanitizedHTMLBody = DOMPurify.sanitize(doc.documentElement.innerHTML);
  return sanitizedHTMLBody;
};

export const getBestLocaleFromList = (
  input: string,
  available: string[],
  defaultLocale = "en"
) => {
  return (
    available.find((locale) => input === locale) ??
    available.find((locale) =>
      (
        (countryLocalesMap as any)[input?.toLocaleUpperCase()] as string[]
      )?.includes(locale)
    ) ??
    defaultLocale
  );
};

/**
 * Returns preferred precision for a given value
 */
export const preferredPrecision = (number: string) => {
  number = number.toString();
  const initialZeros =
    number.replace(".", "").length -
    number.replace(".", "").replace(/^0+/, "").length;
  return initialZeros + 3;
};

/**
 * Return best display for amount
 */
export const formatAmount = (
  number: string | number,
  currency = "eur",
  options?: { split?: boolean; pad?: boolean; locale?: string }
) => {
  const locale = options?.locale;
  number = Number(number).toFixed(6);
  const fractionSeparator = (0.1)
    .toLocaleString(locale)
    .replace(/[0-9]/g, "")[0];

  currency = currency?.toLowerCase() || "";
  const precision = ["kyc"].includes(currency)
    ? 0
    : ["eur", "usd", "gbp"].includes(currency || "")
    ? 2
    : ["usdc", "usdt", "euroc"].includes(currency || "")
    ? 4
    : preferredPrecision(number.toString()) + 1;
  const parts = [
    number.toString().split(".")[0],
    (number.toString().split(".")[1] || "").slice(0, precision),
  ];

  parts[0] = parseFloat(parts[0]).toLocaleString(locale, {
    useGrouping: true,
  });

  parts[1] =
    fractionSeparator +
    parts[1].padEnd(options?.pad !== false ? precision : 2, "0");

  if (options?.split) return parts;

  return parts.join("");
};

export const applySearchFilter = (search: string, item: string) => {
  search = search || "";
  item = item || "";

  //item = search // monkey patch
  const normalizedSearch = search
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "")
    .toLowerCase(); // Normalize and convert to lowercase for case-insensitive comparison

  const normalizedItem = item
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "")
    .toLowerCase(); // Normalize and convert to lowercase for case-insensitive comparison

  const searchWords = normalizedSearch.split(/\s+/); // Split search string into words
  return searchWords.every((word) => normalizedItem.includes(word));
};

export const normalizeString = (str: string) => {
  return str
    .normalize("NFD") // Décompose les lettres en leurs composants diacritiques de base
    .replace(/[\u0300-\u036f]/g, "") // Supprime les diacritiques
    .toLowerCase(); // Convertit la chaîne en minuscules
};

export const normalizeStringToKey = (str: string) => {
  return normalizeString(str).replace(/ +/gm, "_");
};
