import { Tag } from "@atoms/badge/tag";
import { Base, BaseSmall, Info, InfoSmall } from "@atoms/text";
import { AssigneeTag } from "@components/assignations/tag";
import { useAuth } from "@features/auth/state/hooks";
import { extractCustomerFullName } from "@features/customers/utils";
import { useThread } from "@features/inbox/state/use-thread";
import { InboxThread } from "@features/inbox/types";
import { INBOX_STATUS_COLORS } from "@features/inbox/utils";
import { useMessagesLabels } from "@features/messages-labels/state/use-messages-labels";
import { stringToColor } from "@features/utils";
import { formatTime } from "@features/utils/dates";
import { htmlDecodeRawTextContent } from "@features/utils/strings";
import { RectangleStackIcon, PaperClipIcon } from "@heroicons/react/24/outline";
import { getFileType } from "@views/client/customers/customer-details/document-manager";
import _ from "lodash";
import SimpleBar from "simplebar-react";

export default function ThreadCard(props: { thread: InboxThread }) {
  const { labels: tags } = useMessagesLabels();
  const { agent } = useAuth();
  const { thread } = useThread(props.thread.id);
  if (!thread) return <></>;

  const attachmentsCount = thread.attachments?.length;
  const messagesCount = thread.message_count;
  const read =
    !thread?.last_message ||
    thread?.last_message?.read_by_agents?.includes(agent?.agent_id || 0) ||
    thread?.last_message?.delivery !== 4 ||
    false;

  return (
    <div
      className={
        "flex flex-row h-32 py-2 w-full " +
        (thread.status !== 1 ? "opacity-50" : "")
      }
    >
      {/* Side icons (unread, has attachments etc) */}
      <div className="w-8 justify-center shrink-0">
        <div className="h-8 flex items-center justify-center">
          <div
            className={`rounded-full w-3 h-3 ${
              thread.status === 1 && !read
                ? "bg-" +
                  INBOX_STATUS_COLORS[thread.status] +
                  " ring-2 ring-offset-1 ring-blue-500"
                : thread.status === 1 && read
                ? "bg-" + INBOX_STATUS_COLORS[thread.status] + ""
                : thread.status !== 2
                ? "bg-" + INBOX_STATUS_COLORS[thread.status]
                : "bg-gray-500 opacity-0"
            }`}
          />
        </div>
        <div className="flex flex-col space-y-1 items-center justify-center">
          {!!attachmentsCount && (
            <PaperClipIcon className="w-4 h-4 text-gray-500 opacity-50" />
          )}
          {messagesCount > 1 && (
            <RectangleStackIcon className="w-4 h-4 text-gray-500 opacity-50" />
          )}
        </div>
      </div>
      {/* Main body */}
      <div className="grow flex flex-col overflow-hidden pr-2">
        {/* Header */}
        <div className="shrink-0 h-8 flex items-center justify-center flex flex-row">
          <div className="grow overflow-hidden">
            <Base className="!font-medium grow overflow-hidden text-ellipsis whitespace-nowrap block">
              {(thread.customers || [])
                .map((customer) => {
                  return (
                    _.capitalize(extractCustomerFullName(customer)) +
                    (thread.customers.length < 2
                      ? " (" + customer.external_id + ")"
                      : "")
                  );
                })
                .join(", ") || "-"}
            </Base>
          </div>
          <AssigneeTag className="shrink-0 ml-2" type="thread" id={thread.id} />
        </div>

        {/* Last msg title */}
        <div className="h-4 flex items-center justify-center flex flex-row">
          <div className="grow overflow-hidden">
            <BaseSmall className="grow overflow-hidden text-ellipsis whitespace-nowrap block">
              {thread.last_message
                ? htmlDecodeRawTextContent(thread.last_message?.subject) ||
                  "(No subject)"
                : "(No message yet)"}
            </BaseSmall>
          </div>
          <div className="shrink-0">
            <InfoSmall>
              {messagesCount > 1 && messagesCount + " items - "}
              {!!thread.last_message &&
                formatTime(thread.last_message?.created_at || 0)}
            </InfoSmall>
          </div>
        </div>

        {/* Last msg body */}
        <div className="h-10 flex items-start justify-center flex flex-row mt-1">
          <div className="grow overflow-hidden">
            <Info
              className="grow overflow-hidden block text-ellipsis whitespace-pre-wrap"
              style={{
                lineHeight: "1rem",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
              }}
            >
              {thread.last_message
                ? htmlDecodeRawTextContent(thread.last_message?.content) ||
                  "(No content)"
                : "-"}
            </Info>
          </div>
        </div>

        <div className="h-8 flex items-center">
          {!!attachmentsCount && (
            <SimpleBar className="rounded whitespace-nowrap w-1/2 overflow-auto h-8">
              {(thread.attachments || [])?.map((attachment) => {
                const { icon } = getFileType(attachment);
                return (
                  <Tag
                    data-tooltip={attachment.file_name}
                    key={attachment.id}
                    ellipsis
                    className="mr-1 relative h-6 pt-0.5 top-1"
                  >
                    {icon({
                      className: "w-4 h-4 inline relative -top-px mr-px -ml-px",
                    })}
                    {attachment.file_name?.replace(
                      /^(.{6})(.*?)(.{6})$/,
                      "$1…$3"
                    )}
                  </Tag>
                );
              })}
            </SimpleBar>
          )}

          <SimpleBar
            className={
              "rounded whitespace-nowrap overflow-auto h-8 " +
              (attachmentsCount ? "ml-2 w-1/2" : "grow")
            }
          >
            {!thread.tags?.length && (
              <Tag className="opacity-25 h-6 pt-0.5 relative -top-px mt-px top-1">
                No tags
              </Tag>
            )}
            {(thread.tags || [])?.map((tagCode) => {
              const tag = tags.find((t) => t.code === tagCode);
              if (!tag) return null;
              return (
                <Tag
                  key={tag.code}
                  ellipsis
                  noColor
                  className="mr-1 capitalize text-black relative h-6 pt-0.5 top-1"
                  style={{ backgroundColor: stringToColor(tag.code) }}
                >
                  {tag.name}
                </Tag>
              );
            })}{" "}
          </SimpleBar>
        </div>
      </div>
    </div>
  );
}
