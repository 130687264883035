import { Button } from "@atoms/button/button";
import { PageBlock, PageBlockHr } from "@atoms/layout/page-block";
import Link from "@atoms/link";
import { Info, SectionSmall } from "@atoms/text";
import { useCustomer } from "@features/customers/state/use-customer";
import { ROUTES } from "@features/routes";
import { ScoringPreferences } from "@features/scenarios/types";
import {
  SessionAlertType,
  SessionControlResult,
  SessionCustomerType,
} from "@features/sessions/types";
import { buildNameSearchQuery } from "@features/sessions/utils";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { buildTransactionalPressQueries } from "@views/client/customers/customer-details/review";
import { PressReviewContent } from "@views/client/customers/customer-details/reviews/press";
import { ScanReviewContent } from "@views/client/customers/customer-details/reviews/scan";
import { AlertTag } from "./alert-tag";
import { Control } from "./control";
import _ from "lodash";

export default function WatchListReview(props: {
  sessionCustomer: SessionCustomerType;
  alert: SessionAlertType;
  scoringPreferences: ScoringPreferences;
}) {
  const nameSearchQuery = buildNameSearchQuery(
    _.omit(props.sessionCustomer, "country")
  );
  const { customer } = useCustomer(props.sessionCustomer.customer_id);
  return (
    <PageBlock>
      <div id="watchlist" className="flex flex-row justify-between">
        <SectionSmall>Watchlist and Badpress</SectionSmall>
        <AlertTag state={props.alert.state} />
      </div>
      <Info>
        Broad search in PEP and sanction lists and feedback from Go!Scan and
        Go!Press alerts
      </Info>
      <PageBlockHr />
      <SectionSmall className="flex flex-row justify-between">
        Go!Scan (Search in all sanction lists with no filter)
        <div className="self-end flex flex-row items-start space-x-2">
          <Link target="_blank" to={ROUTES.NameSearch + nameSearchQuery}>
            <Button size="sm" theme="outlined">
              <MagnifyingGlassIcon className="h-5 w-5 mr-1 -ml-1" />
              Namesearch
            </Button>
          </Link>
        </div>
      </SectionSmall>
      <div className="flex flex-col -mt-2">
        <Control
          identifier={"watchlist"}
          result={
            props.alert.dow_jones_result &&
            props.alert.dow_jones_result.body.match
              ? SessionControlResult.Error
              : SessionControlResult.Success
          }
          title={
            props.alert.dow_jones_result &&
            props.alert.dow_jones_result.body.match
              ? `Found ${
                  props.alert.dow_jones_result.body.match?.length ?? 0
                } result(s)`
              : "Not found"
          }
        />
      </div>
      {customer && customer?.details?.customer && (
        <>
          <PageBlockHr />
          <SectionSmall className="flex flex-row justify-between">
            Go!Scan (Alerts with filters enabled)
            <div className="self-end flex flex-row items-start space-x-2">
              <Link target="_blank" to={ROUTES.NameSearch + nameSearchQuery}>
                <Button size="sm" theme="outlined">
                  See alerts
                </Button>
              </Link>
            </div>
          </SectionSmall>
          <ScanReviewContent customer={customer} />
        </>
      )}
      {customer && customer?.details?.customer && (
        <>
          <PageBlockHr />
          <SectionSmall className="flex flex-row justify-between">
            Go!Press
            <div className="self-end flex flex-row items-start space-x-2">
              {customer && (
                <Link
                  target="_blank"
                  to={
                    ROUTES.PressInstantReport +
                    buildTransactionalPressQueries(customer?.details?.customer)
                  }
                >
                  <Button size="sm" theme="outlined">
                    <MagnifyingGlassIcon className="h-5 w-5 mr-1 -ml-1" />
                    Search now
                  </Button>
                </Link>
              )}
              {customer && (
                <Link
                  target="_blank"
                  to={ROUTES.PressReportView.replace(
                    ":customer_id",
                    customer.details.customer.id
                  )}
                >
                  <Button size="sm" theme="outlined">
                    See existing report
                  </Button>
                </Link>
              )}
            </div>
          </SectionSmall>
          {customer && <PressReviewContent customer={customer} />}
        </>
      )}
    </PageBlock>
  );
}
