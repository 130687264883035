import { useAuth } from "@features/auth/state/hooks";
import { useGlobalEffect } from "@features/utils";
import { LoadingAtom } from "@features/utils/loading";
import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { InboxSettingsApiClient } from "../api-client/api-client";
import { InboxMailboxesAtom } from "./store";

export const useInboxMailboxes = () => {
  const [mailboxes, setMailboxes] = useRecoilState(InboxMailboxesAtom);
  const { agent } = useAuth();
  const [loading, setLoading] = useRecoilState(
    LoadingAtom("useInboxMailboxes")
  );

  const refresh = useCallback(async () => {
    setLoading(true);
    try {
      setMailboxes(await InboxSettingsApiClient.getMailboxes());
    } catch (e) {
      setLoading(false);
    }
    setLoading(false);
  }, [setMailboxes, setLoading]);

  useGlobalEffect(
    "useInboxMailboxes",
    () => {
      refresh();
    },
    []
  );

  return {
    mailboxes,
    myMailboxes: (mailboxes || []).filter(
      (a) =>
        !agent?.review_groups?.length || // if agent has no review group, show all mailboxes (no filter)
        !a?.review_groups?.length || // if mailbox has no review group do not filter out
        agent?.review_groups.includes("*") || // if agent review groups contains *, show all mailboxes
        (a.review_groups || []).some(
          (g) => (agent?.review_groups || []).some((g2) => g === g2) // if any of the mailboxe's review groups matches any of the agent's review groups do not filter out
        )
    ),
    loading,
    refresh,
  };
};
