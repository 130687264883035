import { ROUTES } from "@features/routes";
import { Edge, MarkerType, Node } from "reactflow";
import {
  RiskFactorElement,
  RiskFlowError,
  RiskNodeOutputKey,
  RiskResourceType,
  RiskTreeData,
  RiskTreeNode,
} from "./types";
import _ from "lodash";

export enum RiskStatus {
  Error = 0,
  Undefined = 1,
  Low = 2,
  Medium = 3,
  High = 4,
  Critical = 5,
}

export const CUSTOM_CUSTOMER_INTERNAL_FIELD = ["age"];

export const RISKS_CODES: any = {
  0: "E",
  1: "U",
  2: "L",
  3: "M",
  4: "H",
  5: "C",
};

export const RISKS_COLORS: any = {
  0: "red-800",
  1: "slate-400",
  2: "green-500",
  3: "yellow-500",
  4: "orange-500",
  5: "red-500",
};

export const RISKS_COLORS_HEX: any = {
  0: "#EF4444",
  1: "#6B7280",
  2: "#10B981",
  3: "#FBBF24",
  4: "#f97316",
  5: "#EF4444",
};

export const RISK_LABEL: any = {
  0: "Error",
  1: "Undefined",
  2: "Low",
  3: "Medium",
  4: "High",
  5: "Critical",
};

export const RISKS_DIGIT: {
  [key in number]: number;
} = {
  [RiskStatus.Undefined]: 0,
  [RiskStatus.Low]: 1,
  [RiskStatus.Medium]: 2,
  [RiskStatus.High]: 3,
  [RiskStatus.Critical]: 4,
};

export const RISK_CODES_LIST = [
  {
    id: RiskStatus["Undefined"],
    digit: RISKS_DIGIT[RiskStatus["Undefined"]],
    value: RISKS_CODES[RiskStatus["Undefined"]],
    label: RISK_LABEL[RiskStatus["Undefined"]],
    color: RISKS_COLORS[RiskStatus["Undefined"]],
    colorHex: RISKS_COLORS_HEX[RiskStatus["Undefined"]],
  },
  {
    id: RiskStatus["Low"],
    digit: RISKS_DIGIT[RiskStatus["Low"]],
    value: RISKS_CODES[RiskStatus["Low"]],
    label: RISK_LABEL[RiskStatus["Low"]],
    color: RISKS_COLORS[RiskStatus["Low"]],
    colorHex: RISKS_COLORS_HEX[RiskStatus["Low"]],
  },
  {
    id: RiskStatus["Medium"],
    digit: RISKS_DIGIT[RiskStatus["Medium"]],
    value: RISKS_CODES[RiskStatus["Medium"]],
    label: RISK_LABEL[RiskStatus["Medium"]],
    color: RISKS_COLORS[RiskStatus["Medium"]],
    colorHex: RISKS_COLORS_HEX[RiskStatus["Medium"]],
  },
  {
    id: RiskStatus["High"],
    digit: RISKS_DIGIT[RiskStatus["High"]],
    value: RISKS_CODES[RiskStatus["High"]],
    label: RISK_LABEL[RiskStatus["High"]],
    color: RISKS_COLORS[RiskStatus["High"]],
    colorHex: RISKS_COLORS_HEX[RiskStatus["High"]],
  },
  {
    id: RiskStatus["Critical"],
    digit: RISKS_DIGIT[RiskStatus["Critical"]],
    value: RISKS_CODES[RiskStatus["Critical"]],
    label: RISK_LABEL[RiskStatus["Critical"]],
    color: RISKS_COLORS[RiskStatus["Critical"]],
    colorHex: RISKS_COLORS_HEX[RiskStatus["Critical"]],
  },
];

export type CustomerStateType =
  | "Required"
  | "Not Required"
  | "Refused"
  | "Passed"
  | "Passed 1 Month"
  | "Passed 3 Month"
  | "Passed 6 Month"
  | "Passed 12 Month"
  | "Passed 24 Month"
  | "Passed 36 Month";

export const RISK_CUSTOMERS_ACTIONS: Record<string, CustomerStateType> = {
  required: "Required",
  not_required: "Passed",
  passed: "Passed",
  failed: "Refused",
  passed_1_month: "Passed 1 Month",
  passed_3_months: "Passed 3 Month",
  passed_6_months: "Passed 6 Month",
  passed_12_months: "Passed 12 Month",
  passed_24_months: "Passed 24 Month",
  passed_36_months: "Passed 36 Month",
};

export const AGGREGATE_OPERATORS = [
  { value: "sum", label: "SUM", tooltip: "Sum" },
  { value: "avg", label: "AVG", tooltip: "Average" },
  { value: "min", label: "MIN", tooltip: "Minimum" },
  { value: "max", label: "MAX", tooltip: "Maximum" },
];

// lt (<) / lte (<=) / gt (>) / gte (>=) / eq (==) / neq (!=)
export const COMPARAISON_OPERATORS = [
  { value: "lt", label: "<", tooltip: "Less than" },
  { value: "lte", label: "<=", tooltip: "Less than or equal to" },
  { value: "gt", label: ">", tooltip: "Greater than" },
  { value: "gte", label: ">=", tooltip: "Greater than or equal to" },
  { value: "eq", label: "==", tooltip: "Equal to" },
  { value: "neq", label: "!=", tooltip: "Not equal to" },
];

export const NODE_START_CUSTOMER: Node = {
  id: "root",
  type: "start",
  deletable: false,
  data: {},
  position: { x: 200, y: 300 },
};

export const NODE_INVISIBLE_UNDEFINED: RiskTreeNode = {
  id: "undefined_masked_leaf",
  leaf: {
    outputs: {
      score: "U",
      action: "not_required",
      alias: "default",
      trigger: "false",
    },
  },
  name: "Undefined Node",
  output_node_ids: {},
};

export const defaultEdgeStyle = {
  style: {
    strokeWidth: 1,
  },
  markerEnd: {
    strokeWidth: 2,
    width: 16,
    height: 16,
    type: MarkerType.ArrowClosed,
  },
};

export const getRiskRouteDetails = (type: RiskResourceType) => {
  switch (type) {
    case "scan":
      return ROUTES.ScanDecisionTree;
    case "press":
      return ROUTES.PressDecisionTree;
    case "customer":
      return ROUTES.CustomerRiskFactorsDetails;
    case "kyt":
      return ROUTES.KytRuleDetails;
    default:
      return ROUTES.CustomerRiskFactorsDetails;
  }
};

export const getRiskRoute = (type: RiskResourceType) => {
  switch (type) {
    case "customer":
      return ROUTES.CustomerRiskFactors;
    case "kyt":
      return ROUTES.KytRules;
    default:
      return ROUTES.CustomerRiskFactors;
  }
};

export const getRiskNodeType: (node: RiskTreeNode) => string = (node) => {
  if (node.leaf) {
    return "leaf";
  }
  if (node.comparison) {
    return "comparison";
  }
  if (node.formula) {
    return "formula";
  }
  if (node.matrix) {
    return "matrix";
  }
  if (node.or) {
    return "or";
  }
  return "start";
};

// Create risk flow elements (nodes & edges)
export const convertRiskElementToNodes: (risk: RiskFactorElement) => {
  nodes: Node[];
  edges: Edge[];
} = (risk: RiskFactorElement) => {
  let riskNodes: Node[] = [];
  let riskEdges: Edge[] = [];

  // remove the undefined node
  // Create root node
  if (risk.type !== "customer_relation") {
    // Customer start node
    riskNodes.push(NODE_START_CUSTOMER);
  } else {
    // Relation start node
    riskNodes.push({
      id: "root",
      type: "relations",
      deletable: false,
      position: { x: 200, y: 300 },
      data: {
        minimum_risk: risk.minimum_risk,
        relation_types: risk.relation_types ?? [],
        risk_factor_ids: risk.risk_factor_ids,
      },
    });
  }

  // Create tree first node
  const firstNode = risk.tree_data.nodes.find(
    (node) => node.id === risk.tree_data.start_node_id
  );

  if (!firstNode) {
    // should not happen, API data is corrupted
    return { nodes: riskNodes, edges: riskEdges };
  }

  const { nodes, edges } = createNodeFlow(
    risk.tree_data.start_node_id,
    risk.tree_data,
    {
      x: 700,
      y: 300,
    }
  );

  riskNodes = riskNodes.concat(nodes);
  riskEdges = riskEdges.concat(edges);

  // attach to root
  const edge: Edge = {
    id: "root" + riskNodes[1].id,
    source: "root",
    target: riskNodes[1].id,
    // deletable: false,
    sourceHandle: "start",
    targetHandle: "from",
    ...defaultEdgeStyle,
  };
  riskEdges.push(edge);

  return { nodes: riskNodes, edges: riskEdges };
};

export const createNodeFlow: (
  nodeId: string | null,
  tree: RiskTreeData,
  position: { x: number; y: number }
) => { nodes: Node[]; edges: Edge[] } = (nodeId, tree, position) => {
  // Create tree first node
  if (nodeId === NODE_INVISIBLE_UNDEFINED.id) {
    return { nodes: [], edges: [] };
  }

  const startNode = tree.nodes.find(
    (node) => node.id === (nodeId ?? tree.start_node_id)
  );

  if (!startNode) {
    // should not happen, API data is corrupted
    return { nodes: [], edges: [] };
  }

  // Create node element
  const type = getRiskNodeType(startNode) as
    | "leaf"
    | "comparison"
    | "formula"
    | "matrix"
    | "or"
    | "start";
  const node: Node = {
    id: startNode.id,
    type: type,
    data: startNode,
    position: { ...position },
    height: type === "leaf" ? 200 : 400,
  };

  if (type === "leaf") {
    return { nodes: [node], edges: [] };
  }

  // we need to create the next nodes
  let nextNodes: Node[] = [];
  let nextEdges: Edge[] = [];

  // for each output node we need to create edges
  const outputs = Object.keys(startNode.output_node_ids) as RiskNodeOutputKey[];
  const defaultOrder = [
    "default",
    "success",
    "low",
    "medium",
    "high",
    "critical",
    "undefined",
  ];
  outputs.sort(
    (a, b) =>
      defaultOrder.findIndex((ai) => a.includes(ai)) -
      defaultOrder.findIndex((bi) => b.includes(bi))
  );

  let yOffset = 0;
  outputs.forEach((output) => {
    const nextNodeId = startNode.output_node_ids[output];
    if (!nextNodeId) {
      return;
    }

    const { nodes, edges } = createNodeFlow(nextNodeId, tree, {
      x: position.x + 500,
      y: position.y + yOffset,
    });

    yOffset =
      yOffset +
      nodes
        .filter((a) => a.id === nextNodeId)
        .reduce((acc, node) => acc + (node.height || 0), 0);

    if (nodes.length > 0) {
      nextNodes = nextNodes.concat(nodes);
      nextEdges = nextEdges.concat(edges);

      // attach to node
      const edge: Edge = {
        id: node.id + nodes[0].id,
        source: node.id,
        target: nodes[0].id,
        sourceHandle: output,
        targetHandle: "from",
        ...defaultEdgeStyle,
      };
      nextEdges.push(edge);
    }
  });

  node.height = yOffset;

  return {
    nodes: _.uniqBy([node].concat(nextNodes), "id"),
    edges: nextEdges,
  };
};

export const convertFlowToRiskTree = (
  nodes: Node<RiskTreeNode>[],
  edges: Edge[],
  resourceType?: RiskResourceType
): { riskFactor?: RiskFactorElement; error?: RiskFlowError } => {
  try {
    // convert flow to risk factor
    let element: RiskFactorElement;
    const root = nodes.find((node) => node.id === "root");
    if (root) {
      const type = root.type;
      const start_node_id = edges.find(
        (edge) => edge.source === "root"
      )?.target;
      if (!start_node_id) {
        return {
          error: {
            node_id: "root",
            message: "Start node is not attached correctly",
          },
        };
      }
      const tree: RiskTreeData = {
        start_node_id: start_node_id,
        nodes: nodes
          .filter((node) => node.id !== "root")
          .map((node) => {
            let defaultOutput = {};

            switch (node.type) {
              case "comparison":
              case "formula":
                defaultOutput = {
                  on_default_node_id: NODE_INVISIBLE_UNDEFINED.id,
                  on_undefined_node_id: NODE_INVISIBLE_UNDEFINED.id,
                  on_success_node_id: NODE_INVISIBLE_UNDEFINED.id,
                };
                break;
              case "matrix":
                defaultOutput = {
                  on_undefined_node_id: NODE_INVISIBLE_UNDEFINED.id,
                  on_low_risk_node_id: NODE_INVISIBLE_UNDEFINED.id,
                  on_medium_risk_node_id: NODE_INVISIBLE_UNDEFINED.id,
                  on_high_risk_node_id: NODE_INVISIBLE_UNDEFINED.id,
                  on_critical_risk_node_id: NODE_INVISIBLE_UNDEFINED.id,
                };
                break;
              default:
                break;
            }

            return {
              ...node.data,
              output_node_ids: {
                ...defaultOutput,
                ...node.data.output_node_ids,
              },
            };
          }),
      };

      // Add invisible node
      tree.nodes.push(
        _.set(
          NODE_INVISIBLE_UNDEFINED,
          "leaf.outputs.score",
          resourceType === "kyt" ? "100" : "L"
        )
      );

      // for each edge we need to update the output_node_ids of the tree node adding sourceHandle: target
      element = {
        id: 0,
        group: "",
        label: "",
        describe: "",
        use_weight: false,
        weight: 1,
        type: type === "start" ? "customer" : "customer_relation",
        tree_data: tree,
      };
      if (type !== "start") {
        // validate relations start node
        const relation_data = root.data as any;
        if (
          relation_data.minimum_risk === undefined ||
          (relation_data.relation_types || []).length === 0 ||
          (relation_data.risk_factor_ids || []).length === 0
        ) {
          // console.error(root.data);
          return {
            error: {
              node_id: root.id,
              message: "Relations start node is not configured correctly",
            },
          };
        }
        element.minimum_risk = relation_data.minimum_risk;
        element.relation_types = relation_data.relation_types || [];
        element.risk_factor_ids = relation_data.risk_factor_ids || [];
      }

      return { riskFactor: element };
    }
  } catch (e) {
    console.error(e);
    return {
      error: {
        node_id: "root",
        message: `${e}`,
      },
    };
  }

  return {
    error: {
      node_id: "root",
      message: "Root node not found",
    },
  };
};

export const getSubLabels = (label: string) => {
  return [
    label.split("|").length > 1 ? label.split("|")[0].trim() : "",
    label.split("|").length > 1
      ? label.split("|").slice(1).join("|").trim()
      : label,
  ];
};
