import { Input } from "@atoms/input/input-text";
import { formatDate } from "@features/utils/dates";
import { TrashIcon } from "@heroicons/react/20/solid";
import { PencilIcon } from "@heroicons/react/24/solid";
import { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { AnnotatedInfos, useAnnotationList } from "./hooks";

type AnnotationProps = {
  identifier: string;
  children: React.ReactNode;
  enabled?: boolean;
};

const Annotation = ({ identifier, children, enabled }: AnnotationProps) => {
  const { listAnnotated, formAnnotated, addElement } = useAnnotationList();
  const [editing, setEditing] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
      setEditing(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [identifier]);

  return (
    <div className="w-full group/annotation" ref={ref}>
      {children}
      {enabled && !!formAnnotated[identifier] && (
        <span
          data-tooltip="Erase"
          className="inline-block h-4 w-4 -mb-0.5 ml-2 hover:text-slate-500 cursor-pointer"
          onClick={() => {
            setEditing(false);
            addElement({ [identifier]: "" });
          }}
        >
          <TrashIcon />
        </span>
      )}
      {enabled && (
        <span
          data-tooltip="Annotate"
          className={twMerge(
            "hidden w-4 group-hover/annotation:inline-block -mb-0.5 ml-2 hover:text-slate-500 cursor-pointer",
            editing && "inline-block"
          )}
          onClick={() => setEditing(!editing)}
        >
          <PencilIcon />
        </span>
      )}
      <div className="w-full">
        {(formAnnotated[identifier] || editing) && (
          <Item
            agentName={"You"}
            date={new Date().toISOString()}
            comment={formAnnotated[identifier]}
            identifier={identifier}
            editing={editing}
            new
          />
        )}
        {listAnnotated.map(
          (item: AnnotatedInfos, index: number) =>
            item.annotated[identifier] && (
              <Item
                key={index + "-" + identifier}
                agentName={item.agent_name}
                date={item.date}
                comment={item?.annotated[identifier]}
                identifier={identifier}
              />
            )
        )}
      </div>
    </div>
  );
};

const Item = ({
  className,
  agentName,
  date,
  identifier: identifier,
  comment,
  editing,
  new: isNew,
}: {
  className?: string;
  comment: string;
  identifier: string;
  agentName?: string;
  date?: string;
  editing?: boolean;
  new?: boolean;
}) => {
  const { addElement, formAnnotated } = useAnnotationList();
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={ref}
      data-technical-id={identifier}
      className={twMerge(
        className,
        "bg-slate-100 rounded-lg my-1",
        isNew && "bg-blue-200 border border-blue-500 dark:bg-blue-900"
      )}
    >
      <div className="py-1 px-2 rounded-md whitespace-normal break-words">
        {!editing && <span className="text-sm leading-5 block">{comment}</span>}
        {editing && (
          <Input
            autoFocus
            multiline
            className="text-wrap mt-1"
            value={formAnnotated[identifier]}
            onChange={(e) => addElement({ [identifier]: e.target.value })}
          />
        )}
        <div className="flex justify-between gap-x-2 items-center mt-1 text-xs font-normal opacity-50">
          {agentName}
          <div className="flex-end">{date && formatDate(date as string)}</div>
        </div>
      </div>
    </div>
  );
};

export default Annotation;
