import { InputDecorationIcon } from "@atoms/input/input-decoration-icon";
import { InputLabel } from "@atoms/input/input-decoration-label";
import { Input } from "@atoms/input/input-text";
import Select from "@atoms/select";
import { BaseSmall } from "@atoms/text";
import { useCustomersCache } from "@features/customers/state/use-customers-cache";
import { useInboxMailboxes } from "@features/inbox-settings/state/use-inbox-mailboxes";
import {
  InboxMessageCreateInput,
  ThreadRelatedCustomer,
} from "@features/inbox/types";
import { MinusCircleIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import ContactsSearchInput from "../../../contact-search-input";
import { TextEditorInput } from "@components/text-editor-input";

type BaseMessage = Pick<
  InboxMessageCreateInput,
  "to" | "cc" | "bcc" | "subject" | "content" | "priority" | "mailbox_id"
>;

export const MessageForm = ({
  value,
  customers,
  onChange,
  disabled,
}: {
  value: Partial<BaseMessage>;
  customers: string[];
  onChange: (
    message: Partial<BaseMessage>,
    to: ThreadRelatedCustomer[]
  ) => void;
  disabled: boolean;
}) => {
  const { customers: cachedCustomers, loading } = useCustomersCache(customers);
  const [openCc, setOpenCc] = useState(false);
  const [openBcc, setOpenBcc] = useState(false);
  const [toCustomers, setToCustomers] = useState<ThreadRelatedCustomer[]>([]);
  const { myMailboxes, loading: mbLoading } = useInboxMailboxes();

  const selectedMailbox = myMailboxes.find((a) => a.id === value.mailbox_id);

  return (
    <InputLabel
      label="Message"
      input={
        <div className={"flex flex-col group " + (loading ? "opacity-75" : "")}>
          {!mbLoading && myMailboxes.length > 1 && (
            <Select
              className="rounded-b-none focus:z-10 -mb-px"
              value={value.mailbox_id}
              onChange={(e) => {
                onChange(
                  {
                    ...value,
                    mailbox_id: e.target.value,
                  },
                  toCustomers
                );
              }}
            >
              {myMailboxes.map((m) => (
                <option key={m.id} value={m.id}>
                  From: {m.account_name}
                </option>
              ))}
            </Select>
          )}
          <div className="flex flex-row group space-x-2 items-center relative -mb-px">
            <div className="grow">
              <ContactsSearchInput
                className={
                  (myMailboxes.length > 1 ? "rounded-none" : "rounded-b-none") +
                  " focus:z-10"
                }
                placeholder="To"
                auto={value.to?.length === 0}
                value={value?.to?.split(", ") ?? []}
                customers={customers ?? []}
                additionalEmails={(selectedMailbox?.recipient_groups || [])
                  .join(",")
                  .split(/,|;/g)
                  .map((a: string) => a.trim().toLocaleLowerCase())}
                onContactsChange={(contacts, customers) => {
                  onChange(
                    {
                      ...value,
                      to: contacts.join(", "),
                    },
                    customers
                  );
                  setToCustomers(customers);
                }}
              />
            </div>
            <div className="absolute right-3 flex flex-row group space-x-2 items-center">
              {!toCustomers.length && (
                <BaseSmall
                  onClick={() => {
                    const customers = cachedCustomers.map(
                      (c) =>
                        ({
                          ...c,
                          id: c.customer_id,
                        } as ThreadRelatedCustomer)
                    );
                    onChange(
                      {
                        ...value,
                        to: cachedCustomers.map((c) => c.contacts).join(", "),
                      },
                      customers
                    );
                    setToCustomers(customers);
                  }}
                  noColor
                  className="cursor-pointer hover:text-blue-700 text-blue-600 bg-white dark:bg-transparent"
                >
                  Everyone
                </BaseSmall>
              )}
              {!openCc && (
                <BaseSmall
                  onClick={() => {
                    setOpenCc(true);
                    onChange(
                      {
                        ...value,
                        cc: "",
                      },
                      toCustomers
                    );
                  }}
                  noColor
                  className="cursor-pointer hover:text-blue-700 text-blue-600 bg-white dark:bg-transparent"
                >
                  Cc
                </BaseSmall>
              )}
              {!openBcc && (
                <BaseSmall
                  noColor
                  onClick={() => {
                    setOpenBcc(true);
                    onChange(
                      {
                        ...value,
                        bcc: "",
                      },
                      toCustomers
                    );
                  }}
                  className="cursor-pointer hover:text-blue-700 text-blue-600 bg-white dark:bg-transparent"
                >
                  Bcc
                </BaseSmall>
              )}
            </div>
          </div>

          {openCc && (
            <InputDecorationIcon
              className="-mb-px"
              input={({ className }) => (
                <ContactsSearchInput
                  className={className + " rounded-none focus:z-10"}
                  placeholder="Cc"
                  value={value?.cc?.split(", ") ?? []}
                  customers={customers ?? []}
                  onContactsChange={(contacts) => {
                    onChange(
                      {
                        ...value,
                        cc: contacts.join(", "),
                      },
                      toCustomers
                    );
                  }}
                />
              )}
              suffix={({ className }) => {
                className =
                  "absolute m-auto top-0 bottom-0 right-3 cursor-pointer h-5 w-5 hover:text-blue-700 text-blue-600";
                return (
                  <MinusCircleIcon
                    onClick={() => setOpenCc(false)}
                    className={className}
                  />
                );
              }}
            />
          )}
          {openBcc && (
            <InputDecorationIcon
              className="-mb-px"
              input={({ className }) => (
                <ContactsSearchInput
                  className={className + " rounded-none focus:z-10"}
                  placeholder="Bcc"
                  value={value?.bcc?.split(", ") ?? []}
                  customers={customers ?? []}
                  onContactsChange={(contacts) => {
                    onChange(
                      {
                        ...value,
                        bcc: contacts.join(", "),
                      },
                      toCustomers
                    );
                  }}
                />
              )}
              suffix={({ className }) => {
                className =
                  "absolute m-auto top-0 bottom-0 right-3 cursor-pointer h-5 w-5 hover:text-blue-700 text-blue-600";
                return (
                  <MinusCircleIcon
                    onClick={() => setOpenBcc(false)}
                    className={className}
                  />
                );
              }}
            />
          )}
          <div className="flex flex-row -mb-px">
            <Input
              className="grow rounded-none focus:z-10"
              placeholder="Subject"
              value={value?.subject ?? ""}
              onChange={(e) => {
                onChange(
                  {
                    ...value,
                    subject: e.target.value,
                  },
                  toCustomers
                );
              }}
              disabled={disabled}
            />
            <Select
              value={value?.priority ?? 3}
              onChange={(e) => {
                onChange(
                  {
                    ...value,
                    priority: parseInt(e.target.value),
                  },
                  toCustomers
                );
              }}
              className="shrink-0 !w-auto rounded-none focus:z-10 -ml-px"
            >
              <option value={1}>Urgent</option>
              <option value={2}>Important</option>
              <option value={3}>Normal</option>
              <option value={4}>Low priority</option>
            </Select>
          </div>
          <TextEditorInput
            className="rounded-lg rounded-r-none rounded-t-none min-h-[10rem] max-h-[20rem] overflow-auto whitespace-pre-wrap"
            focusOnlyClassName="ring-1 ring-blue-600 !border-blue-600"
            placeholder="Write your message..."
            value={value?.content ?? ""}
            onChange={(e) => {
              onChange(
                {
                  ...value,
                  content: e,
                },
                toCustomers
              );
            }}
            disabled={disabled}
          />
        </div>
      }
    />
  );
};
