import { PageBlockHr } from "@atoms/layout/page-block";
import { Info, SectionSmall } from "@atoms/text";
import { ModalImage } from "@components/modal-image";
import Env from "@config/environment";
import { SessionAlertType } from "@features/sessions/types";
import { flatMapControls } from "@features/sessions/utils";
import { AlertTag } from "./alert-tag";
import { Controls } from "./controls";
import { SessionBlock } from "./session-block";

export default function FaceMatchReview(props: {
  alert: SessionAlertType;
  anonymized: boolean;
}) {
  return (
    <SessionBlock
      id="face-question"
      title="Face Match"
      subtitle="Face analysis and comparison"
      suffix={<AlertTag state={props.alert.state} />}
      tries={props.alert.questions?.length}
      render={(tryIndex) => {
        const similarityScore =
          props.alert.controls?.[tryIndex as number]?.find(
            (a) => a.identifier === "face.matched.similarity"
          )?.score || (props.alert.score || 0) / 100;

        return (
          <>
            {props.alert.score !== undefined && (
              <>
                {props.alert.questions?.[tryIndex as number].answers
                  ?.videos?.[0] &&
                  !props.anonymized && (
                    <>
                      <div className="flex flex-wrap gap-4 mt-4 justify-center">
                        <video
                          className="bg-black h-96 w-auto object-cover rounded-md border border-slate-300"
                          src={`${Env.server}${
                            props.alert.questions?.[tryIndex as number].answers
                              ?.videos[0].url
                          }`}
                          controls
                          preload="auto"
                        />
                      </div>
                    </>
                  )}

                <PageBlockHr />
                <div className="flex flex-col space-y-2">
                  <SectionSmall>Controls</SectionSmall>
                  <Info>
                    These controls have been generated automatically by our
                    systems. Please make further verifications if needed.
                  </Info>
                  <div className="pt-2">
                    <Controls
                      annotable
                      controls={flatMapControls(
                        props.alert.controls?.[tryIndex as number] || []
                      )}
                    />
                  </div>
                </div>
                {!props.anonymized && (
                  <>
                    <PageBlockHr />
                    <div className="flex flex-col space-y-2">
                      <SectionSmall>Faces</SectionSmall>
                      {props.alert.identity_faces?.[tryIndex as number] && (
                        <Info>
                          Score obtained matching webcam faces and identity face
                          below :
                        </Info>
                      )}
                      {!props.alert.identity_faces?.[tryIndex as number] && (
                        <Info>Faces detected during the session</Info>
                      )}
                      <div className="flex flex-row gap-2 flex-wrap justify-center overflow-x-auto py-2">
                        {(props.alert.questions?.[tryIndex as number]?.answers
                          .attachment_urls?.length ?? 0) === 0 && (
                          <Info>
                            No faces were detected during the session.
                          </Info>
                        )}
                        {(
                          props.alert.questions?.[tryIndex as number]?.answers
                            ?.attachment_urls ?? []
                        ).map((url, index) => (
                          <ModalImage
                            key={index}
                            className="w-24 h-24 object-cover rounded-md border border-slate-300 "
                            small={`${Env.server}/api/v1/downloads/${url.id}`}
                            large={`${Env.server}/api/v1/downloads/${url.id}`}
                            alt="Face"
                          />
                        ))}
                        {props.alert.identity_faces?.[tryIndex as number] && (
                          <>
                            <div className="w-24 h-24 text-5xl justify-center flex items-center pb-2">
                              {similarityScore > 0.9 ? "=" : "≠"}
                            </div>
                            <ModalImage
                              className="w-auto h-24 object-cover rounded-md border border-slate-300"
                              alt="Face"
                              small={`${Env.server}/api/v1/downloads/${
                                props.alert.identity_faces?.[tryIndex as number]
                                  ?.id
                              }`}
                              large={`${Env.server}/api/v1/downloads/${
                                props.alert.identity_faces?.[tryIndex as number]
                                  ?.id
                              }`}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        );
      }}
    />
  );
}
