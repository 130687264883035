import { Button } from "@atoms/button/button";
import { useSessionDecisions } from "@features/sessions/state/use-session-decisions";
import SessionDecisionAddModal, {
  SessionDecisionAddModalAtom,
} from "@views/client/vid/session-details/components/decision-add-modal";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { AnnotationContext, FormAnnotated, useAnnotationList } from "./hooks";

type ChildProps = {
  children?: React.ReactNode;
};

export const AnnotationProvider: React.FC<ChildProps> = ({ children }) => {
  const { id } = useParams();
  const { decisions } = useSessionDecisions(id as string);
  const [formAnnotated, setFormAnnotated] = useState<FormAnnotated>({});
  const resetFormAnnotated = () => setFormAnnotated({});

  const addElement = (item: FormAnnotated) => {
    const val = { ...formAnnotated, ...item };
    for (const key in val) {
      if (!val[key]) delete val[key];
    }
    return setFormAnnotated(val);
  };

  return (
    <AnnotationContext.Provider
      value={{
        ressourceID: id as string,
        decisions,
        formAnnotated,
        listAnnotated: decisions
          .filter((i) => i.annotated)
          .map((i) => ({
            agent_name: i.agent_name || "",
            annotated: JSON.parse(i.annotated),
            date: i.date,
          })),
        addElement,
        resetFormAnnotated,
      }}
    >
      {Object.keys(formAnnotated).length > 0 && <AnnotationPanel />}
      {children}
    </AnnotationContext.Provider>
  );
};

export const AnnotationPanel = () => {
  const [modal, setModal] = useRecoilState(SessionDecisionAddModalAtom);
  const { ressourceID, formAnnotated, decisions, resetFormAnnotated } =
    useAnnotationList();

  const handleEmulateNewAnnotation = () => {
    setModal({
      ...modal,
      status: decisions[decisions.length - 1].status_id,
      open: true,
      session_id: ressourceID,
      annotated: formAnnotated,
      children: (
        <p className="text-slate-400 italic">
          {Object.keys(formAnnotated).length} annotation
          {Object.keys(formAnnotated).length > 1 && "s"} added
        </p>
      ),
      handleSubmit: () => resetFormAnnotated(),
    });
  };

  return (
    <div className="fixed top-28 w-screen items-center z-20">
      <div className="flex flex-row mx-auto w-[50%] dark:bg-slate-900 border rounded-lg p-4 shadow-sm  bg-white space-x-2">
        <div className="m-auto">
          {`${Object.keys(formAnnotated).length}`} annotations added. Do you
          want to save them?
        </div>
        <Button onClick={handleEmulateNewAnnotation}>Save</Button>
      </div>
      <SessionDecisionAddModal />
    </div>
  );
};
