import React, { ReactNode } from "react";
import SimpleBar from "simplebar-react";
import { twMerge } from "tailwind-merge";

// @ts-ignore
interface TabsProps extends React.InputHTMLAttributes<HTMLInputElement> {
  tabs: { value: string | number; label: JSX.Element | string }[];
  value: string | number;
  onChange: (value: string | number) => void;
  parentClassName?: string;
}

const defaultTabClassName =
  " text-sm cursor-pointer h-10 px-3 flex items-center border-b-2 border-transparent hover:text-blue-600 transition-colors";
const activeTabClassName = " text-blue-500 border-blue-500 ";
const inactiveTabClassName = " text-zinc-500 ";

export default function Tabs(props: TabsProps) {
  return (
    <>
      <SimpleBar>
        <div
          className={twMerge(
            `flex w-100 border-b border-zinc-200 dark:border-zinc-800 transition-all select-none`,
            props.className
          )}
        >
          {props.tabs.map((tab) => {
            const cl = twMerge(
              defaultTabClassName,
              tab.value === props.value
                ? activeTabClassName
                : inactiveTabClassName,
              props.parentClassName
            );
            return (
              <div
                key={tab.value}
                className={cl}
                onClick={() => props.onChange(tab.value)}
              >
                {tab.label}
              </div>
            );
          })}
        </div>
      </SimpleBar>
    </>
  );
}

export const FloatingTabs = ({
  tabs,
  value,
  onChange,
  children,
}: {
  tabs: {
    label: string;
    value: string;
  }[];
  value?: string;
  onChange: (value: string | number) => void;
  children?: ReactNode;
}) => {
  return (
    <div
      style={{ zIndex: 11 }}
      className="sticky top-0 flex flex-row items-center bg-slate-200 dark:bg-slate-700 rounded"
    >
      <div className={twMerge("rounded-md overflow-hidden flex-1")}>
        <Tabs
          parentClassName="mx-2"
          className={twMerge("bg-slate-200 dark:bg-slate-700 px-0 pt-1")}
          tabs={tabs}
          onChange={onChange}
          value={value || "jump"}
        />
      </div>
      <div className="mr-2">{children}</div>
    </div>
  );
};
